import React from 'react'

import 'react-image-gallery/styles/css/image-gallery.css'
import styles from './Home.module.scss'
import {SoundCloud} from "../../SoundCloud/SoundCloud";

import likes from '../../SoundCloud/likes.json';

const getLinksFromDreamtechLikes = (): string[] => {
    return likes.collection.map((collection: { track: { permalink_url: string } }) => collection.track.permalink_url)
}

const Home = () =>
    (
        <div className={styles.home}>
            <div className={styles.construction}>
                Hash House Pizza Currently under construction. Please check back later.


                For now, enjoy a bunch of fire tunes.

                <div>
                    For business inquiries please contact adam.smith@hashhousepizza.com
                </div>

                {
                    getLinksFromDreamtechLikes().map((link) => <SoundCloud url={link}/>)
                }
            </div>
        </div>
    )

export default Home
